<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <v-card-title>
        <div class="headline">{{$t('accounts.users.editDialog.title')}}</div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="ma-2" no-gutters>

            <!-- Firstname -->
            <v-col cols="6">
              <v-text-field
                :label="$t('accounts.users.labels.firstname')"
                outlined
                :rules="requiredRules"
                v-model="form.person.firstname"
                maxlength="255" />
            </v-col>

            <!-- Name -->
            <v-col cols="6">
              <v-text-field
                :label="$t('accounts.users.labels.name')"
                outlined
                :rules="requiredRules"
                v-model="form.person.name"
                maxlength="255" />
            </v-col>

            <!-- Role -->
            <v-col cols="12">
              <v-select
                :items="$t('constants.usersRoles.list')"
                :rules="requiredRules"
                v-model="form.role"
                outlined
                :label="$t('accounts.users.labels.role')"
              />
            </v-col>

            <!-- Lang -->
            <v-col cols="12">
              <SelectSimple
                :items="['fr', 'en']"
                :isRequired="true"
                :value="form.preferences.lang"
                :label="$t('accounts.users.labels.lang')"
                @input="(val) => form.preferences.lang = val" />
            </v-col>

            <!-- Enabled -->
            <v-col cols="12">
              <v-switch
                v-model="form.enabled"
                :label="$t('accounts.users.labels.enabled')"
              />
            </v-col>

            <v-col cols="12" class="mt-4">
              <AlertBar />
            </v-col>

            <v-col cols="12">
              <ButtonBarFormDialog
                class="mt-5"
                :isLoadingBtnSave="isLoading.btnSave"
                v-on:cancel="close(false)"
                v-on:submit="customSubmit"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules from "@/mixins/formRules";

export default {
  name: "AccountUserEditDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectSimple: () => import("@/components/Common/Inputs/SelectGeneric"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog")
  },

  props: {
    accountType: {type: String, default: null, enum: ["BRAND", "GROUP", "FACTORY"]},
    accountId: {type: String, default: null}
  },

  data() {
    return {

      valid: null,
      isLoadingBtn: {btnSave: false},
      showPassword: false,

      defaultForm: {
        person: {
          firstname: "",
          name: ""
        },
        role: "",
        preferences: {
          lang: "fr"
        },
        enabled: false
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {

        this.isLoadingBtn.btnSave = true;
        this.$http
            .put(`/users/${this.item.id}`, {
              ...this.form
            },
            {
              headers: {Authorization: "Bearer " + this.$session.get('jwt')}
            })
            .then(res => {
              this.$store.commit("toast/showSuccess", this.$t('accounts.users.editDialog.success'));
              this.close(true);
            })
            .catch(err => {
              this.$store.commit("alert/showErrorAxios", err);
            })
            .finally(() => {
              this.isLoadingBtn.btnSave = false;
            });
      }
    },
  }
}
</script>

<style scoped>

</style>
